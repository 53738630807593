<template>
  <div class="hallBattleBox">
    <header class="headBox">
      <p></p>
      <span class="rulesBtn" @click="changeRulesType(1)">{{ $t('tingzhan_rule_buttom') }}</span>
    </header>
    <!-- tab按钮 -->
     <ul class="tabBox">
      <li :class="eventType >= 0 ? 'actTab' : ''" @click="changeTabType(1)">
        <span class="actSelect" v-if="tabType == 0 || tabType == 1"></span>
        <p>{{ $t('tingzhan_tab1') }}</p>
        <p>{{ $t('tingzhan_tab1_time') }}</p>
      </li>
      <li :class="eventType >= 2 ? 'actTab' : ''" @click="changeTabType(2)">
        <span class="actSelect"  v-if="tabType == 2"></span>
        <p>{{ $t('tingzhan_tab2') }}</p>
        <p>{{ $t('tingzhan_tab2_time') }}</p>
      </li>
      <li :class="eventType >= 3 ? 'actTab' : ''" @click="changeTabType(3)">
        <span class="actSelect" v-if="tabType == 3"></span>
        <p>{{ $t('tingzhan_tab3') }}</p>
        <p>{{ $t('tingzhan_tab3_time') }}</p>
      </li>
      <li :class="eventType >= 4 ? 'actTab' : ''" @click="changeTabType(4)">
        <span class="actSelect" v-if="tabType == 4"></span>
        <p>{{ $t('tingzhan_tab4') }}</p>
        <p>{{ $t('tingzhan_tab4_time') }}</p>
      </li>
     </ul>
     <!-- 内容 -->
     <ul class="tabContentBox ">
      <!-- tab1 -->
      <li class="tab1Box" v-if="tabType == 0 || tabType == 1">
         <div class="rulesBox">
            <header class="rulesHead">{{ $t('tingzhan_tab1_rule_row1') }}</header>
            <p>{{ $t('tingzhan_tab1_rule_row2') }} </p>
            <p>{{ $t('tingzhan_tab1_rule_row3') }}</p>
            <p>{{ $t('tingzhan_tab1_rule_row4') }}</p>
         </div> 
         <!-- 榜单 -->
         <rankTemplate :dangerNumber="dangerNumber" dangerIndex="0" v-if="rankData.length > 0" :rankData = "rankData"/>
         <div  v-else class="nullBox">
          <div></div>
         </div>
      </li>
      <!-- tab2 -->
      <li class="tab1Box tab2Box" v-if="tabType == 2">
        <div class="subTabBox">
          <p :class="subTabType == 1 ? 'goldBox' : ''" @click="changeSubTab(1)"> 
            <span>{{ $t('tingzhan_tab_gold') }}</span>
            <span class="dian" v-if="subTabType == 1"></span>
          </p>
          <p :class="subTabType == 2 ? 'silverBox' : ''"  @click="changeSubTab(2)">
            <span>{{ $t('tingzhan_tab_silver') }}</span>
            <span class="dian" v-if="subTabType == 2"></span>
          </p>
        </div>
        <!-- 黄金 -->
        <div v-if="subTabType == 1" class="goldRankBox">
          <div class="rulesBox">
            <header class="rulesHead">{{ $t('tingzhan_tab2_goldrule_row1') }}</header>
            <p>{{ $t('tingzhan_tab2_goldrule_row2') }}</p>
            <p>{{ $t('tingzhan_tab1_goldrule_row3') }}</p>
         </div> 
         <!-- 榜单 -->
         <rankTemplate :dangerNumber="dangerNumber" dangerIndex="1" v-if="rankData.length > 0" :rankData = "rankData"/>
         <div  v-else class="nullBox">
          <div></div>
         </div>
        </div>
        <!-- 白银 -->
        <div v-if="subTabType == 2" class="silverRankBox">
          <div class="rulesBox">
            <header class="rulesHead">{{ $t('tingzhan_tab2_silverrule_row1') }}</header>
            <p>{{ $t('tingzhan_tab2_silverrule_row2') }}</p>
            <p>{{ $t('tingzhan_tab2_silverrule_row3') }}</p>
         </div> 
         <!-- 榜单 -->
         <rankTemplate :dangerNumber="dangerNumber" dangerIndex="2" v-if="rankData.length > 0" :rankData = "rankData"/>
         <div  v-else class="nullBox">
          <div></div>
         </div>
        </div>
      </li>
      <!-- tab3 -->
      <li class="tab1Box tab2Box tab3Box" v-if="tabType == 3">
        <div class="subTabBox">
          <p :class="subTabType == 1 ? 'goldBox' : ''" @click="changeSubTab(1)"> 
            <span>{{ $t('tingzhan_tab_gold') }}</span>
            <span class="dian" v-if="subTabType == 1"></span>
          </p>
          <p :class="subTabType == 2 ? 'silverBox' : ''"  @click="changeSubTab(2)">
            <span>{{ $t('tingzhan_tab_silver') }}</span>
            <span class="dian" v-if="subTabType == 2"></span>
          </p>
        </div>
        <!-- 黄金 -->
        <div v-if="subTabType == 1" class="goldRankBox">
          <div class="rulesBox">
            <header class="rulesHead">{{ $t('tingzhan_tab3_goldrule_row1') }}</header>
            <p>{{ $t('tingzhan_tab3_goldrule_row2') }}  </p>
            <p>{{ $t('tingzhan_tab3_goldrule_row3') }}  </p>
            <p>{{ $t('tingzhan_tab3_goldrule_row4') }}  </p>
         </div> 
         <!-- pk榜单 -->
         <ul class="pkRankBox" v-if="rankData.length > 0">
          <li v-for="(item,index) in pkDataIndex" :key="index">
            <div class="pkLeft" @click="toAppRoom(rankData[item.left])">
              <div class="picBox">
                <template v-if="eventType == 4">
                  <span class="win" v-if="rankData[item.left].state == 1"></span>
                  <span class="revive" v-if="rankData[item.left].state == 2"></span>
                  <span class="silver" v-if="rankData[item.left].state == 0"></span>
                </template>
                <div class="pkRoomInfo">
                  <img v-if="rankData[item.left].headIcon" :src="rankData[item.left].headIcon" alt="">
                  <img v-else src="../../assets/hallBattle/roomNull.png" alt="">
                  <span>{{ rankData[item.left].name }}</span>
                  <span class="liveIcon" v-if="rankData[item.left].micState == 1"></span>
                </div>
              </div>
              <div class="moneyUserBox">
                <p class="roomMoney">{{rankData[item.left].score | formatNumber}}</p>
                <ul class="roomTop3User">
                    <li v-for="subItem in rankData[item.left].topList" :key="subItem.uid" @click.stop="skipUserInfo(subItem)">
                        <img v-if="subItem.headIcon" :src="subItem.headIcon" alt="">
                        <img v-else src="../../assets/hallBattle/userNull2.png" alt="">
                    </li>
                    <li v-if="!rankData[item.left].topList[0]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.left].topList[1]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.left].topList[2]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>   
                </ul>
              </div>
            </div>
            <!-- pk条 -->
            <div class="progressBox">
              <p class="progressLeft" :style="'width: ' + countProgress(rankData[item.left],rankData[item.right])"></p>
              <span class="progressIcon" :style="'left: ' + countProgress(rankData[item.left],rankData[item.right])"></span>
              <p class="progressRight" :style="'width: ' + countProgress(rankData[item.right],rankData[item.left])"></p>
            </div>
            <div class="pkLeft pkRight" @click="toAppRoom(rankData[item.right])">
              <div class="picBox">
                <template v-if="eventType == 4">
                  <span class="win" v-if="rankData[item.right].state == 1"></span>
                  <span class="revive" v-if="rankData[item.right].state == 2"></span>
                  <span class="silver" v-if="rankData[item.right].state == 0"></span>
                </template>
                <div class="pkRoomInfo">
                  <img v-if="rankData[item.right].headIcon" :src="rankData[item.right].headIcon" alt="">
                  <img v-else src="../../assets/hallBattle/roomNull.png" alt="">
                  <span>{{ rankData[item.right].name }}</span>
                  <span class="liveIcon" v-if="rankData[item.right].micState == 1"></span>
                </div>
              </div>
              <div class="moneyUserBox">
                <p class="roomMoney">{{rankData[item.right].score | formatNumber}}</p>
                <ul class="roomTop3User">
                    <li v-for="subItem in rankData[item.right].topList" :key="subItem.uid" @click.stop="skipUserInfo(subItem)">
                        <img v-if="subItem.headIcon" :src="subItem.headIcon" alt="">
                        <img v-else src="../../assets/hallBattle/userNull2.png" alt="">
                    </li>
                    <li v-if="!rankData[item.right].topList[0]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.right].topList[1]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.right].topList[2]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>   
                </ul>
              </div>
            </div>
          </li>
         </ul>
         <div  v-else class="nullBox">
          <div></div>
         </div>
        </div>
        <!-- 白银 -->
        <div v-if="subTabType == 2" class="silverRankBox">
          <div class="rulesBox">
            <header class="rulesHead">{{ $t('tingzhan_tab3_silverrule_row1') }}</header>
            <p>{{ $t('tingzhan_tab3_silverrule_row2') }}</p>
            <p>{{ $t('tingzhan_tab3_silverrule_row3') }}</p>
         </div> 
         <!-- pk榜单 -->
         <ul class="pkRankBox" v-if="rankData.length > 0">
          <li v-for="(item,index) in pkDataIndex" :key="index">
            <div class="pkLeft" @click="toAppRoom(rankData[item.left])">
              <div class="picBox">
                <template v-if="eventType == 4">
                  <span class="win" v-if="rankData[item.left].state == 1"></span>
                  <span class="revive" v-if="rankData[item.left].state == 2"></span>
                  <span class="out" v-if="rankData[item.left].state == 0"></span>
                </template>
                <div class="pkRoomInfo">
                  <img v-if="rankData[item.left].headIcon" :src="rankData[item.left].headIcon" alt="">
                  <img v-else src="../../assets/hallBattle/roomNull.png" alt="">
                  <span>{{ rankData[item.left].name }}</span>
                  <span class="liveIcon" v-if="rankData[item.left].micState == 1"></span>
                </div>
              </div>
              <div class="moneyUserBox">
                <p class="roomMoney">{{rankData[item.left].score | formatNumber}}</p>
                <ul class="roomTop3User">
                    <li v-for="subItem in rankData[item.left].topList" :key="subItem.uid" @click.stop="skipUserInfo(subItem)">
                        <img v-if="subItem.headIcon" :src="subItem.headIcon" alt="">
                        <img v-else src="../../assets/hallBattle/userNull2.png" alt="">
                    </li>
                    <li v-if="!rankData[item.left].topList[0]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.left].topList[1]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.left].topList[2]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>   
                </ul>
              </div>
            </div>
            <!-- pk条 -->
            <div class="progressBox">
              <p class="progressLeft" :style="'width: ' + countProgress(rankData[item.left],rankData[item.right])"></p>
              <span class="progressIcon" :style="'left: ' + countProgress(rankData[item.left],rankData[item.right])"></span>
              <p class="progressRight" :style="'width: ' + countProgress(rankData[item.right],rankData[item.left])"></p>
            </div>
            <div class="pkLeft pkRight" @click="toAppRoom(rankData[item.right])">
              <div class="picBox">
                <template v-if="eventType == 4">
                  <span class="win" v-if="rankData[item.right].state == 1"></span>
                  <span class="revive" v-if="rankData[item.right].state == 2"></span>
                  <span class="out" v-if="rankData[item.right].state == 0"></span>
                </template>
                <div class="pkRoomInfo">
                  <img v-if="rankData[item.right].headIcon" :src="rankData[item.right].headIcon" alt="">
                  <img v-else src="../../assets/hallBattle/roomNull.png" alt="">
                  <span>{{ rankData[item.right].name }}</span>
                  <span class="liveIcon" v-if="rankData[item.right].micState == 1"></span>
                </div>
              </div>
              <div class="moneyUserBox">
                <p class="roomMoney">{{rankData[item.right].score | formatNumber}}</p>
                <ul class="roomTop3User">
                    <li v-for="subItem in rankData[item.right].topList" :key="subItem.uid" @click.stop="skipUserInfo(subItem)">
                        <img v-if="subItem.headIcon" :src="subItem.headIcon" alt="">
                        <img v-else src="../../assets/hallBattle/userNull2.png" alt="">
                    </li>
                    <li v-if="!rankData[item.right].topList[0]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.right].topList[1]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>
                    <li v-if="!rankData[item.right].topList[2]">
                        <img src="../../assets/hallBattle/userNull.svg" alt="">
                    </li>   
                </ul>
              </div>
            </div>
          </li>
         </ul>
         <div  v-else class="nullBox">
          <div></div>
         </div>
        </div>
      </li>
      <!-- tab4 -->
      <li class="tab1Box tab2Box tab4Box" v-if="tabType == 4">
        <div class="subTabBox">
          <p :class="subTabType == 1 ? 'goldBox' : ''" @click="changeSubTab(1)"> 
            <span>{{ $t('tingzhan_tab_gold') }}</span>
            <span class="dian" v-if="subTabType == 1"></span>
          </p>
          <p :class="subTabType == 2 ? 'silverBox' : ''"  @click="changeSubTab(2)">
            <span>{{ $t('tingzhan_tab_silver') }}</span>
            <span class="dian" v-if="subTabType == 2"></span>
          </p>
        </div>
        <!-- 黄金 -->
        <div v-if="subTabType == 1" class="goldRankBox">
          <div class="rulesBox">
            <header class="rulesHead">{{ $t('tingzhan_tab4_rule_row1') }}</header>
            <p>{{ $t('tingzhan_tab4_rule_row2') }} </p>
            <p>{{ $t('tingzhan_tab4_rule_row3') }} </p>
         </div> 
         <!-- 倒计时 -->
         <div class="countdownBox">
            <header>{{ $t("tingzhan_tab4_countdown") }}</header>
            <div class="timeBox" v-if="$i18n.locale == 'ar'">
              <div class="hourBox">
                <span>{{hours[1]}}</span>
                <span>{{hours[0]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="minuteBox">
                <span>{{minutes[1]}}</span>
                <span>{{minutes[0]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="secondBox">
                <span>{{seconds[1]}}</span>
                <span>{{seconds[0]}}</span>
              </div>
            </div>
            <div class="timeBox" v-else>
              <div class="hourBox">
                <span>{{hours[0]}}</span>
                <span>{{hours[1]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="minuteBox">
                <span>{{minutes[0]}}</span>
                <span>{{minutes[1]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="secondBox">
                <span>{{seconds[0]}}</span>
                <span>{{seconds[1]}}</span>
              </div>
            </div>
         </div>
        <!-- 榜单 --> 
          <ul class="top4AfterBox"  v-if="rankData.length > 0">
            <li v-for="(item,index) in rankData" :key="index">
              <section :class="index > dangerNumber[3] ? 'dangerRoom' : ''" @click="toAppRoom(item)">
                <div class="rankCount">
                  {{ item.top | zeroFillTop }}
                </div>
                <div class="roomHead" :class="'roomTop' + index">
                    <img class="toukuang" v-if="index == 0" src="../../assets/hallBattle/top1Kuang.png" alt="">
                    <img class="toukuang" v-if="index == 1" src="../../assets/hallBattle/top2Kuang.png" alt="">
                    <img class="toukuang" v-if="index == 2" src="../../assets/hallBattle/top3Kuang.png" alt="">

                    <img class="roomPic" v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else src="../../assets/hallBattle/roomNull.png" alt="">
                    <span v-if="item.micState == 1" class="liveIcon"></span>
                </div>
                <div class="roomNameUserIcon">
                    <p class="roomName"> {{ item.name }}</p>
                    <ul class="roomTop3User" v-if="item.topList">
                        <li v-for="subItem in item.topList" :key="subItem.uid" @click.stop="skipUserInfo(subItem)">
                            <img v-if="subItem.headIcon" :src="subItem.headIcon" alt="">
                            <img v-else src="../../assets/hallBattle/userNull2.png" alt="">
                        </li>
                        <li v-if="!item.topList[0]">
                            <img src="../../assets/hallBattle/userNull.svg" alt="">
                        </li>
                        <li v-if="!item.topList[1]">
                            <img src="../../assets/hallBattle/userNull.svg" alt="">
                        </li>
                        <li v-if="!item.topList[2]">
                            <img src="../../assets/hallBattle/userNull.svg" alt="">
                        </li>   
                    </ul>
                </div>
                <p class="roomMoney">{{ item.score | formatNumber }}</p>
              </section>
              <div class="dangerTips" v-if="index == dangerNumber[3]">
                  <div>Danger</div>
              </div>
            </li>
          </ul>
          <div  v-else class="nullBox">
          <div></div>
         </div>
        </div>
        <!-- 白银 -->
        <div v-if="subTabType == 2" class="silverRankBox">
          <div class="rulesBox">
            <header class="rulesHead">{{ $t('tingzhan_tab4_rule_row1') }}</header>
            <p>{{ $t('tingzhan_tab4_rule_row2') }} </p>
            <p>{{ $t('tingzhan_tab4_rule_row3') }} </p>
         </div> 
        <!-- 倒计时 -->
        <div class="countdownBox">
            <header>{{ $t("tingzhan_tab4_countdown") }}</header>
            <div class="timeBox" v-if="$i18n.locale == 'ar'">
              <div class="hourBox">
                <span>{{hours[1]}}</span>
                <span>{{hours[0]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="minuteBox">
                <span>{{minutes[1]}}</span>
                <span>{{minutes[0]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="secondBox">
                <span>{{seconds[1]}}</span>
                <span>{{seconds[0]}}</span>
              </div>
            </div>
            <div class="timeBox" v-else>
              <div class="hourBox">
                <span>{{hours[0]}}</span>
                <span>{{hours[1]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="minuteBox">
                <span>{{minutes[0]}}</span>
                <span>{{minutes[1]}}</span>
              </div>
              <div class="colon">:</div>
              <div class="secondBox">
                <span>{{seconds[0]}}</span>
                <span>{{seconds[1]}}</span>
              </div>
            </div>
         </div>
        <!-- 榜单 --> 
          <ul class="top4AfterBox" v-if="rankData.length > 0">
            <li v-for="(item,index) in rankData" :key="index">
              <section :class="index > dangerNumber[3] ? 'dangerRoom' : ''" @click="toAppRoom(item)">
                <div class="rankCount">
                  {{ item.top | zeroFillTop }}
                </div>
                <div class="roomHead" :class="'roomTop' + index">
                    <img class="toukuang" v-if="index == 0" src="../../assets/hallBattle/top1Kuang.png" alt="">
                    <img class="toukuang" v-if="index == 1" src="../../assets/hallBattle/top2Kuang.png" alt="">
                    <img class="toukuang" v-if="index == 2" src="../../assets/hallBattle/top3Kuang.png" alt="">
                    <img class="roomPic" v-if="item.headIcon" :src="item.headIcon" alt="">
                    <img v-else src="../../assets/hallBattle/roomNull.png" alt="">
                    <span v-if="item.micState == 1" class="liveIcon"></span>
                </div>
                <div class="roomNameUserIcon">
                    <p class="roomName"> {{ item.name }}</p>
                    <ul class="roomTop3User">
                        <li v-for="subItem in item.topList" :key="subItem.uid" @click.stop="skipUserInfo(subItem)">
                            <img v-if="subItem.headIcon" :src="subItem.headIcon" alt="">
                            <img v-else src="../../assets/hallBattle/userNull2.png" alt="">
                        </li>
                        <li v-if="!item.topList[0]">
                            <img src="../../assets/hallBattle/userNull.svg" alt="">
                        </li>
                        <li v-if="!item.topList[1]">
                            <img src="../../assets/hallBattle/userNull.svg" alt="">
                        </li>
                        <li v-if="!item.topList[2]">
                            <img src="../../assets/hallBattle/userNull.svg" alt="">
                        </li>
                    </ul>
                </div>
                <p class="roomMoney">{{ item.score | formatNumber }}</p>
              </section>
              <div class="dangerTips" v-if="index == dangerNumber[3]">
                  <div>Danger</div>
              </div>
            </li>
          </ul>
          <div  v-else class="nullBox">
          <div></div>
         </div>
        </div>
      </li>
     </ul>
     <!-- 规则 -->
     <div class="rulesPopBox" v-if="rulesType" :class="rulesTabType == 1 ? 'heightRulesPopBox' : ''">
        <div class="popContent"  :class="rulesTabType == 1 ? 'scrollPopcontent' : ''">
          <div class="rulesCloseBtn" @click="changeRulesType(0)"></div>
          <div class="rulesTabContent">
            <div class="rulesTab">
              <p :class="rulesTabType == 0 ? 'actLeftTab' : ''" @click="changeRulesTab(0)">
                <span>{{ $t('tingzhan_rule_tab1') }} </span>
                <span class="dian" v-if="rulesTabType == 0"></span>
              </p>
              <p :class="rulesTabType == 1 ? 'actRightTab' : ''" @click="changeRulesTab(1)">
                <span>{{ $t('tingzhan_rule_tab2') }} </span>
                <span class="dian" v-if="rulesTabType == 1"></span>
              </p>
            </div>
            <ul class="rulesTabGold"  v-if="rulesType" :class="rulesTabType == 1 ? 'heightRulesTabGold' : ''">
              <li v-if="rulesTabType == 0">
                <div class="rulesBox">
                    <header class="rulesHead">{{ $t('tingzhan_time_title') }}</header>
                    <p>{{ $t('tingzhan_time') }} </p>
                </div> 
                <div class="rulesGift">
                  <header class="rulesHead">{{ $t('tingzhan_rule_tab1_title') }}</header>
                  <ul class="giftBox">
                    <li>
                      <div>
                        <img src="../../assets/hallBattle/eventGift1.png" alt="">
                      </div>
                      <p>{{ $t("tingzhan_rule_luvkygift1") }}</p>
                      <p>300</p>
                    </li>
                    <li>
                      <div>
                        <img src="../../assets/hallBattle/eventGift2.png" alt="">
                      </div>
                      <p>{{ $t("tingzhan_rule_gift2") }}</p>
                      <p>5000</p>
                    </li>
                    <li>
                      <div>
                        <img src="../../assets/hallBattle/eventGift3.png" alt="">
                      </div>
                      <p>{{ $t("tingzhan_rule_gift3") }}</p>
                      <p>50000</p>
                    </li>
                    <li>
                      <div>
                        <img src="../../assets/hallBattle/eventGift4.png" alt="">
                      </div>
                      <p>{{ $t("tingzhan_rule_gift4") }}</p>
                      <p>100000</p>
                    </li>
                  </ul>
                </div>
              </li>
              <li v-if="rulesTabType == 1">
                <div class="rulesBox">
                    <header class="rulesHead">{{ $t('tingzhan_tab2_banner_title') }}</header>
                    <p>{{ $t('tingzhan_tab2_banner_body') }} </p>
                </div> 
                <div class="subRulesTabBox">
                  <p :class="subRulesTabType==1?'actSubRulesTab':''" @click="changeSubRulesTab(1)">{{ $t('tingzhan_tab_gold') }}</p>
                  <p :class="subRulesTabType==2?'actSubRulesTab':''" @click="changeSubRulesTab(2)">{{ $t('tingzhan_tab_silver') }}</p>
                </div>
                <div class="subRulesContent">
                  <!-- gold奖励 -->
                  <div class="subRulesGold" v-show="subRulesTabType==1">
                    <div class="goldRoom">
                      <header class="rulesHead">{{ $t('tingzhan_tab2_roomreward') }}</header>
                      <div class="formHead">
                        <span>{{ $t('tingzhan_tab2_reward') }}</span>
                        <span>{{ $t('tingzhan_tab2_usd') }}</span>
                        <span>{{ $t("tingzhan_tab2_roombg") }}</span>
                        <span>{{ $t('tingzhan_roomcover') }}</span>
                      </div>
                      <div class="formBody">
                        <span>{{ $t('tingzhan_1stplace') }}</span>
                        <span>500</span>
                        <span>2</span>
                        <span></span>
                      </div>
                      <div class="formBody">
                        <span>{{ $t('tingzhan_2ndplace') }}</span>
                        <span>300</span>
                        <span>-</span>
                        <span></span>
                      </div>
                      <div class="formBody">
                        <span>{{ $t('tingzhan_3rdplace') }}</span>
                        <span>200</span>
                        <span>-</span>
                        <span></span>
                      </div>
                    </div>
                    <div class="supporterBox1">
                      <header class="rulesHead">{{ $t('tingzhan_supporter_1stplace') }}</header>
                      <div class="goldGiftBox">
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/giftToukuang.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_avatar') }} </p>
                          <p>*{{ $t('tingzhan_30days') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/gift1.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_entrance') }} </p>
                          <p>*{{ $t('tingzhan_30days') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/id.png" alt="">
                          </div>
                          <!-- <p>头像框 </p> -->
                          <p>{{ $t('tingzhan_tab2_specialid_body1') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div class="swiperBox">
                            <div class="swiper-container" id="swiperIndex" ref="swiperIndex">
                              <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                  <div>
                                    <img src="../../assets/hallBattle/gift4_1.png" alt="">
                                  </div>
                                </div>
                                <div class="swiper-slide">
                                  <div>
                                    <img src="../../assets/hallBattle/gift4_2.png" alt="">
                                  </div>
                                </div>
                                <div class="swiper-slide">
                                  <div>
                                    <img src="../../assets/hallBattle/gift4_3.png" alt="">
                                  </div>
                                </div>
                                <div class="swiper-slide">
                                  <div>
                                    <img src="../../assets/hallBattle/gift4_4.png" alt="">
                                  </div>
                                </div>
                              </div>
                                <!-- <p>头像框 </p> -->
                                <p>{{ $t('tingzhan_rule_gift4_name') }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                    <div class="supporterBox1 supporterBox2">
                      <header class="rulesHead">{{ $t('tingzhan_supporter_2ndplace') }}</header>
                      <div class="goldGiftBox">
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/giftToukuang.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_avatar') }} </p>
                          <p>{{ $t('tingzhan_15days') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/gift1.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_entrance') }} </p>
                          <p>{{ $t('tingzhan_15days') }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="supporterBox1 supporterBox2 supporterBox3">
                      <header class="rulesHead">{{ $t('tingzhan_supporter_3rdplace') }}</header>
                      <div class="goldGiftBox">
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/giftToukuang.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_avatar') }} </p>
                          <p>{{ $t('tingzhan_7days') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/gift1.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_entrance') }} </p>
                          <p>{{ $t('tingzhan_7days') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- silver奖励 -->
                  <div class="subRulesSilver" v-show="subRulesTabType==2">
                    <div class="goldRoom">
                      <header class="rulesHead">{{ $t('tingzhan_tab2_roomreward') }}</header>
                      <div class="formHead">
                        <span>{{ $t('tingzhan_tab2_reward') }}</span>
                        <span>{{ $t('tingzhan_tab2_usd') }}</span>
                        <span>{{ $t("tingzhan_tab2_roombg") }}</span>
                        <span>{{ $t('tingzhan_roomcover') }}</span>
                      </div>
                      <div class="formBody">
                        <span>{{ $t('tingzhan_1stplace') }}</span>
                        <span>400</span>
                        <span>2</span>
                        <span></span>
                      </div>
                      <div class="formBody">
                        <span>{{ $t('tingzhan_2ndplace') }}</span>
                        <span>200</span>
                        <span>-</span>
                        <span></span>
                      </div>
                      <div class="formBody">
                        <span>{{ $t('tingzhan_3rdplace') }}</span>
                        <span>100</span>
                        <span>-</span>
                        <span></span>
                      </div>
                    </div>
                    <div class="supporterBox1">
                      <header class="rulesHead">{{ $t('tingzhan_supporter_1stplace') }}</header>
                      <div class="goldGiftBox">
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/giftToukuangSilver.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_avatar') }} </p>
                          <p>*{{ $t('tingzhan_15days') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/id.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_specialid_body2') }} </p>
                          <!-- <p>*30 days</p> -->
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/gift1Silver.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_entrance') }} </p>
                          <p>*{{ $t('tingzhan_15days') }}</p>
                        </div>
                      </div>
                      
                    </div>
                    <div class="supporterBox1 supporterBox2">
                      <header class="rulesHead">{{ $t('tingzhan_supporter_2ndplace') }}</header>
                      <div class="goldGiftBox">
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/giftToukuangSilver.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_avatar') }} </p>
                          <p>*{{ $t('tingzhan_7days') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/gift1Silver.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_entrance') }} </p>
                          <p>*{{ $t('tingzhan_7days') }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="supporterBox1 supporterBox2 supporterBox3">
                      <header class="rulesHead">{{ $t('tingzhan_supporter_3rdplace') }}</header>
                      <div class="goldGiftBox">
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/giftToukuangSilver.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_avatar') }} </p>
                          <p>*{{ $t('tingzhan_3days') }}</p>
                        </div>
                        <div class="goldGiftItem">
                          <div>
                            <img src="../../assets/hallBattle/gift1Silver.png" alt="">
                          </div>
                          <p>{{ $t('tingzhan_tab2_entrance') }} </p>
                          <p>*{{ $t('tingzhan_3days') }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="rulesTips">{{ $t('tingzhan_tab2_other') }}</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
     </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
import rankTemplate from "./rank.vue"
import "swiper/css/swiper.css"; //引入swiper样式
import Swiper from "swiper"; //引入swiper
export default {
  name: "hallBattle",
  components:{
    rankTemplate
  },
  data() {
    return{
        client:'',
      // 危险区开始下标
      dangerNumber:[15,5,3,2],
      // tab切换
      tabType:'0',
      // 黄金白银tab切换 // 黄金白银未分组 分组id(0 未分组 1黄金组 2 白银组)
      subTabType:'0',
      // 赛事阶段
      eventType:'0',
      // 规则tab切换
      rulesTabType:'0',
      subRulesTabType:'0',
      rulesType:0,
      // 活动时间
      days:[0,0],
      hours:[0,0],
      minutes:[0,0],
      seconds:[0,0],
      // 榜单数据
      rankData:[],
      // pk数据下标
      pkDataIndex:[
        {
          left:'0',
          right:'5'
        },{
          left:'1',
          right:'4'
        },{
          left:'2',
          right:'3'
        }
      ],
      // 阶段状态 （0: 未开始 1：进行中 2：结束）
      stageState: 0
    }
  },
  filters:{
    formatNumber(num) {
      if (num > 1000000) {
          return Math.trunc((num / 1000000) * 10) / 10 + 'M';
      } else if (num >= 1000) {
          return Math.trunc((num / 1000) * 10) / 10 + 'K';
      } else {
          return num.toString ();
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    },
  },
  created() { 
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()

    // this.getUserInfo() 
  },
  methods: {
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      this.langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
      this.headers = {
          fid:  this.userInfo.fid,
          os:  this.userInfo.os || 1,
          lang: this.userInfo.lang, 
          version:this.userInfo.version,
          deviceid:this.userInfo.deviceId,
        }
  //  this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJOemt5TWpneiIsInh5eiI6IlJUQTNORFZCTkRBdE1qUkdRaTAwT1RKQ0xVRXhNak10T1VFMFJEUkdNek14UVVJdyIsImV4cCI6MTk4NDAyNTYzMH0.KrZfZ2yE5MHJ28PRPqUrVETKmHnJSZeeV-PYJYcxIoU';
  //     this.uid = '792283'; 
  //     this.headers = {
  //         fid: '1001',
  //         os: 1,
  //         lang: 'zh-CN',
  //         version: '3.0',
  //         deviceid: 'E0745A40-24FB-492B-A123-9A4D4F331AB0',
  //     }
  //     let langs = 'en'
  //     this.langs = 'ar'
  //     let lang = 'zh_CN'
      document.title = this.$t('tingzhan_title')
      if (langs == "ar") {
          this.$i18n.locale = "ar";
      } else {
          this.$i18n.locale = "en";
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/hall/info?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
            this.eventType = data.data.curStage;
            this.stageState = data.data.stageState;
            // this.eventType = 1;
            // 决赛开启倒计时
            if(this.eventType == 4){
              this.finalsDownTime(data.data.curEnd)
            }
            this.tabType = this.eventType;
            this.changeTabType(this.tabType)
        }else{
          Toast(data.message);
        }
      });
      
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
        if (this.client == 'iOS') {
            window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.uid));
        } else if (this.client == 'Android') {
            nativeJs["skipUserInfo"](JSON.stringify(item.uid));
        }
    },
    // 跳转房间
    toAppRoom(item){
        if (this.client == 'iOS') {
            window["webkit"].messageHandlers['languageRoom'].postMessage(JSON.stringify(item.roomId));
        } else if (this.client == 'Android') {
            nativeJs["languageRoom"](JSON.stringify(item.roomId));
        }
    },
    // 渲染swiper
    showSwiper(){
      var that = this;
      this.$nextTick(()=>{
        this.mySwiper = new Swiper("#swiperIndex", {
          // speed: 500,
          loop: true,
          initialSlide:0,
          allowTouchMove: false, // 禁止手动滑动
          autoplay: {
            delay: 1000,
            stopOnLastSlide: false,
            disableOnInteraction:false,
          }, //可选选项，自动滑动
          
        });
      })
     
      
      // this.mySwiper = new Swiper("#swiperIndex", {
        
      // });
    },
    // 获取房间榜数据
    getRoomRank() {
      this.rankData = [];
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIpJava +
          "api/activity/hall/room/page?token=" +
          this.token +
          "&uid=" +
          this.uid +
          "&stage=" +
          this.tabType +
          "&page=" + 0 +
          "&size=" + 30 +
          "&group=" + this.subTabType,
        data: "",
        lang: this.langs,
        header: this.headers,
      };
      this.$axios.api(personLeveldatas.host,personLeveldatas.data,personLeveldatas.option,personLeveldatas.header).then((data) => {
        if (data.code == 200) {
          this.rankData = data.data.list;
        } else {
          Toast(data.message);
        }
      });
    },
    // 决赛倒计时设置
    finalsDownTime(endTime) {
      this.timer = setInterval( ()=> {
        const now = new Date().getTime();
        const distance = endTime - now;
        
        if (distance < 0) {
          clearInterval(this.timer);
          return;
        }
        const days = this.zeroFill(Math.floor(distance / (1000 * 60 * 60 * 24)));
        const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));

        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }, 1000);
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },
    // 规则展示隐藏
    changeRulesType(type){
      this.rulesType = type;
      this.rulesTabType = 0;
      this.subRulesTabType = 1;
      if(type){
        this.stopScroll()
      }else{
        this.canScroll();
      }
    },
    // 规则内小tab切换
    changeSubRulesTab(type){
      this.subRulesTabType = type;
    },
    // 切换规则展示
    changeRulesTab(type){
      this.rulesTabType = type;
      if( type == 1){
        this.showSwiper()
      }
    },
    // 切换赛事
    changeTabType(type){
      // 活动未开始
      if(type == 1 && this.stageState == 0){
          Toast(this.$t('tingzhan_notstart'));
          return;
      }
      // 切换赛程
      if(type <= this.eventType){
        this.tabType = type;
        // 第一个赛程不分组
        if(type == 1){
          this.subTabType = 0
        }else {
          this.subTabType = 1
        }
        // 获取房间榜数据
        this.getRoomRank()
      }
      
    },
    // 黄金白银切换
    changeSubTab(type){
      this.subTabType = type;
      // 获取房间榜数据
      this.getRoomRank()
    },
    // 计算百分比
    countProgress(data,data2){
      let score = data.score
      let score2 = data2.score
      if(score == score2){
        return '50%'
      }else{
        return (score / (score + score2) * 100)  + '%'
      }
      
    }
  }
};
</script>

<style scoped>
@import './index.css';
</style>
